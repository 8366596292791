<template>
<v-card flat class="app-background" height="100%">
  <v-overlay :value="showProgress">
    <v-progress-circular :size="65" :width="5" color="#d82e3d" indeterminate/>
  </v-overlay>
  <v-card class="card-background rounded-lg">
    <v-container fill-height fluid class="mt-4 mb-8 pa-0">
        <v-row align="center" justify="center" no-gutters>
            <v-col align="center" justify="center" no-gutters>
                <p class="welcome">Welcome to Your Virtual Meeting</p>
                <v-card class="card rounded-lg">
                    <v-row class="card-header" align="center" justify="center" no-gutters>
                        <span class="quick-start">Quick Start</span>
                    </v-row>
                    <v-col class="ma-0 pa-0">
                        <div style="height: 40px;"/>
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="12" lg="4"><p class="form-title">Scheduled Time: </p></v-col>
                            <v-col cols="12" sm="12" md="12" lg="8"><p class="form-value">{{ this.startTime }}</p></v-col>
                        </v-row>
                        
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="12" lg="4"><p class="form-title">Meeting no: </p></v-col>
                            <v-col cols="12" sm="12" md="12" lg="8"><p class="form-value">{{ this.meetingNo }}</p></v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="12" lg="4"><p class="form-title">Subject: </p></v-col>
                            <v-col cols="12" sm="12" md="12" lg="8"><p class="form-value">{{ this.subject }}</p></v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="12" lg="4"><p class="form-title">Participant(s): </p></v-col>
                            <v-col cols="12" sm="12" md="12" lg="8"><p class="form-value">{{ this.participants }}</p></v-col>
                        </v-row>

                        <v-btn class="start primary--text" color="primary" @click="start">
                            Start the meeting
                            <v-icon right dark >
                                {{"mdi-arrow-right"}}
                            </v-icon>
                        </v-btn>

                        <div style="height: 40px;"/>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
  </v-card>
</v-card>
</template>

<script>
    import moment from 'moment';    
import Vue from 'vue';
    export default {
        name: 'Admin',
        data() {
            return {
                startTime: "",
                subject: "",
                participants: "",
                meetingNo: "",
                showProgress: true,
                token: this.$route.query.token,
            };
        },

        mounted() {
            this.$store.dispatch("auth/setAuth", {
                name: null,
                token: null
            });
            this.checkMeetingStatus(this.token, 'Y');
            this.checkBrowser();
        },

        methods: {
            // checkToken() {
            //     if(this.token==="token") {
            //         this.checkTime();
            //     } else {
            //         this.$router.push("/NoPermission");
            //     }
            // },

            // checkTime() {
            //     var start = moment(this.startTime, "MM/DD/YYYY hh:mm:ss");
            //     var end = moment(this.endTime, "MM/DD/YYYY hh:mm:ss");
            //     var now = moment();

            //     if(!now.isBetween(start, end)){
            //         this.$router.push("/Waiting");
            //     }
            // },

            checkBrowser() {
                // Opera 8.0+
                var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
                // Firefox 1.0+
                var isFirefox = typeof InstallTrigger !== 'undefined';
                // Safari 3.0+ "[object HTMLElementConstructor]" 
                var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
                // Internet Explorer 6-11
                var isIE = /*@cc_on!@*/false || !!document.documentMode;
                // Edge 20+
                var isEdge = !isIE && !!window.StyleMedia;
                // Chrome 1 - 71
                var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

                var isMobileDevice = !!(/Android|webOS|iPhone|iPad|iPod|BB10|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(navigator.userAgent || ''));
                
                if(!isMobileDevice) {
                    if(!isEdge && !isChrome && !isSafari) this.$router.push("/BrowserSupport");
                }
            },

            checkMeetingStatus(token, isHost) {
                this.$api._meeting
                    .checkMeetingStatus({
                        token: token,
                        ishost: isHost
                    })
                    .then((response) => {
                        this.$store.dispatch("tokenInfo/setTokenInfo", {
                            subject: response.data.data.subject,
                            staffName: response.data.data.staff_name,
                            meetingNo: response.data.data.meeting_no,
                            availableStartTime: response.data.data.available_start_datetime,
                        });
                        this.startTime = moment(String(this.$store.state.tokenInfo.availableStartTime)).format('MM/DD/yyyy hh:mm a').toUpperCase();
                        this.subject = this.$store.state.tokenInfo.subject;
                        this.participants = this.$store.state.tokenInfo.staffName;
                        this.meetingNo = this.$store.state.tokenInfo.meetingNo;
                        this.showProgress = false;
                    });
            },

            start() {
                if(this.token){
                    this.$store.dispatch("auth/setAuth", {
                        name: this.$store.state.tokenInfo.staffName,
                        token: this.token
                    });
                    
                    this.$api._meeting
                        .addMeetingAttendee({
                            attendeename: this.$store.state.auth.name,
                            token: this.token,
                        });

                    this.$router.replace({
                        name: "AdminVideo",
                        query: {
                            token: this.token
                        }
                    });
                }
            },
        }
    };
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

    .app-background{
        background-image: linear-gradient(to bottom, #fbfbfb, #f4f8fc);
    }

    .card-background {
        height: 100%;
        margin: 32px 28px 0px 28px;
        background-image: linear-gradient(to bottom, #f9f9f9, #f4f8fc);
    }

    .welcome {
        padding: 0px;
        margin: 0 0 40px 0;
        font-family: "Lato";
        font-size: 35px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #243c49;
    }

    .card {
        width: 45%;
        padding: 0;
        margin: 0;
    }

    .card-header {
        padding: 0;
        margin: 0;
        height: 5%;
        background-image: linear-gradient(93deg, #f1f1f1 0%, #e9eff0 99%);
    }

    .quick-start {
        padding: 0px;
        margin: 20px;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;  
        color: #243c49;
    }

    .form-title {
        padding: 0px;
        margin: 10px 16px 10px 0;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #243c49;
        text-align: end;
    }

    .form-value {
        padding: 0px;
        margin: 10px 0 10px 0px;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #243c49;
        text-align: start;
    }

    .start {
        width: 230px;
        margin: 20px 0 0 0;
        padding: 0px;
        font-family: 'Lato';
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: none !important;
        background-image: linear-gradient(93deg, #f1f1f1 0%, #e9eff0 99%);
    }


    @media screen and (max-width: 960px) {
        .card-background {
            height: 100%;
            margin: 0px;
            background-image: linear-gradient(to bottom, #f9f9f9, #f4f8fc);
        }

        .card {
            width: 90%;
            padding: 0;
            margin: 0;
        }

        .welcome {
            padding: 0px;
            margin: 0 0 40px 0;
            font-family: "Lato";
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #243c49;
        }

        .quick-start {
            padding: 0px;
            margin: 20px;
            font-size: 16px;
            font-family: 'Lato';
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;  
            color: #243c49;
        }

        .form-title {
            padding: 0px;
            margin: 12px 0 7px 0;
            font-size: 12px;
            font-family: 'Lato';
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #243c49;
            text-align: center;
        }

        .form-value {
            padding: 0px;
            margin: 0 0 12px 0;
            font-size: 14px;
            font-family: 'Lato';
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #243c49;
            text-align: center;
        }
    }
</style>
